<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    const { next } = this.$route.query
    if (next) this.$router.replace(next)
  }
}
</script>
